<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.65824 13.3827C8.54151 13.8239 8.14084 14.1302 7.68444 14.1269L6.69166 14.1199C5.96968 14.1208 5.24476 14.4059 4.76478 14.8744C4.30642 15.3217 4 16.1245 4 16.8412C4 17.5154 4.33717 18.3262 4.82373 18.8011C5.31593 19.2814 5.98856 19.5555 6.69488 19.5555H13.236L13.2367 19.5555C14.1423 19.5561 15.0283 19.3088 15.7939 18.8437C16.5592 18.3787 17.1722 17.7156 17.5662 16.9325C17.9355 16.1985 18.0659 15.1326 17.9695 14.2051C17.8795 13.34 17.5426 12.5145 16.9939 11.8227C16.4451 11.1306 15.7059 10.5997 14.8583 10.2934C14.0105 9.98716 13.0907 9.91885 12.2042 10.0969C11.3178 10.275 10.5032 10.6916 9.852 11.2964C9.22859 11.8753 8.8917 12.5003 8.65824 13.3827Z"
      :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 14.5C0.447715 14.5 0 14.9477 0 15.5C0 16.0523 0.447715 16.5 1 16.5V14.5ZM1 16.5H5V14.5H1V16.5Z"
          :fill="iconColor"/>
    <path d="M18 15.5H23" :stroke="iconColor" stroke-width="2" stroke-linecap="round"/>
    <path d="M20.5 18L23 15.5L20.5 13" :stroke="iconColor" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>

<style>
</style>