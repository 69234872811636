<template>
  <v-dialog
    transition="fade-transition"
    absolute
    width="100vw"
    ref="dialog"
    v-model="isOpen"
    persistent
    hide-overlay
    :overlay-opacity="0"
    v-if="isOpen"
  >
    <div class="card-overlay" @click="$refs.dialog.animateClick()" />

    <v-card elevation="8" style="max-width: calc(100% - 48px); width: 600px">
      <!-- <v-card-title
        ref="dialog-start"
        class="px-10 w-100 justify-space-between"
        id="modal-start"
      >
        <h4 class="font-weight-light">New Installation</h4>

        <v-btn x-small icon @click="$emit('removeModal')" class="close-model">
          <v-icon>$close</v-icon>
        </v-btn>
      </v-card-title> -->

      <v-card-text height="800px" class="px-8 pt-8">
        <div class="mb-8 d-flex align-start">
          <div class="d-flex flex-column">
            <h4 class>New Installation</h4>
          </div>

          <v-btn x-small icon @click="$emit('removeModal')" class="ml-auto">
            <v-icon size="24" color="gray">$close</v-icon>
          </v-btn>
        </div>

        <v-divider class="mb-5" />

        <v-tabs v-model="tabs" class="mb-6" v-show="hasAdvancedFields">
          <v-tab>Basic Configuration</v-tab>
          <v-tab v-show="hasAdvancedFields">Advanced Configuration</v-tab>
        </v-tabs>

        <h4 v-if="submittingError" style="color: #c00">
          {{ submittingError }}
        </h4>
        <h4 v-if="submittingSuccess" style="color: #0c0">
          {{ submittingSuccess }}
        </h4>

        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <v-form class="form-box" ref="form">
              <v-row no-gutters>
                <v-col cols="12" class="pb-0 d-flex flex-column">
                  <label class="base--text p-3 mb-2"> Site Name </label>
                  <v-text-field
                    outlined
                    large
                    class="v-input--md"
                    placeholder="My WordPress website name"
                    v-model="newInstanceForm.name"
                    :rules="siteNameRules"
                    hide-details="auto"
                    validate-on-blur
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0 d-flex flex-column">
                  <label class="base--text p-3 mb-2"> Domain </label>

                  <div class="d-flex input-group">
                    <v-select
                      class="mr-4"
                      v-model="newInstanceForm.domainProtocol"
                      item-value="id"
                      item-text="name"
                      :items="availableDomainProtocols"
                      outlined
                      dense
                      required
                      :menu-props="{
                        offsetY: true,
                      }"
                      content
                      style="max-width: 128px"
                    >
                      <template v-slot:no-data>
                        <loader />
                      </template>
                    </v-select>
                    <v-text-field
                      outlined
                      hide-details="auto"
                      validate-on-blur
                      placeholder="domainname.com"
                      :rules="domainRules"
                      v-model="newInstanceForm.domain"
                      class="v-input--md"
                      dense
                      required
                    ></v-text-field>

                    <template v-if="!subdirectoryInputOpen">
                      <v-btn
                        large
                        text
                        @click="subdirectoryInputOpen = true"
                        class="ml-4"
                      >
                        <span class="font-weight-light">Set Subdirectory</span>
                      </v-btn>
                    </template>

                    <template v-else>
                      <div class="d-flex">
                        <div class="mx-4 p-2 text--heading mt-3">/</div>
                        <v-text-field
                          outlined
                          hide-details="auto"
                          validate-on-blur
                          placeholder="folder"
                          v-model="newInstanceForm.dir"
                          class="v-input--md"
                          dense
                          style="max-width: 160px"
                        >
                          <template v-slot:append>
                            <v-btn icon x-small @click="closeSubdirectoryInput">
                              <v-icon size="16">$close</v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </div>
                    </template>
                  </div>
                </v-col>
              </v-row>
              <div class="tabs-container">
                <v-tabs v-model="activeTab" :show-arrows="false" class="pb-4">
                  <!-- <v-tab
                    :disabled="wordpressNoTemplates"
                    @change="newInstanceForm.install_type = 'from_template'"
                    v-ripple="false"
                    class="p-1"
                  >
                    <v-tooltip bottom :disabled="!wordpressNoTemplates">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-on="on" v-bind="attrs">
                          Install From Template
                          <v-icon
                            v-if="wordpressNoTemplates"
                            class="ml-2"
                            size="16"
                            color="gray lighten-1"
                            >$help</v-icon
                          >
                        </span>
                      </template>
                      <i class="error--text p-4">
                        No Templates to install from!
                      </i>
                    </v-tooltip>
                  </v-tab> -->
                  <v-tab
                    @change="newInstanceForm.install_type = 'clean'"
                    v-ripple="false"
                    class="p-1"
                    >New Clean Installation</v-tab
                  >
                  <v-tab
                    @change="newInstanceForm.install_type = 'clone'"
                    v-ripple="false"
                    :disabled="noInstances"
                    class="p-1"
                  >
                    <v-tooltip bottom :disabled="!noInstances">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-on="on" v-bind="attrs">
                          Clone Existing Installation
                          <v-icon
                            v-if="noInstances"
                            class="ml-2"
                            size="16"
                            color="gray lighten-1"
                            >$help</v-icon
                          >
                        </span>
                      </template>
                      <i class="error--text p-4">
                        No Instances to Clone from!
                      </i>
                    </v-tooltip>
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="activeTab">
                  <!-- INSTALL FROM TEMPLATE FORM -->
                  <!-- <v-tab-item>
                    <div cols="12" class="d-flex flex-column">
                      <span
                        class="error--text mt-3 mb-6"
                        v-if="wordpressNoTemplates"
                        >No Templates Available!</span
                      >
                      <v-select
                        v-else
                        v-model="newInstanceForm.template"
                        :items="wordpressTemplates"
                        item-text="name"
                        item-value="id"
                        outlined
                        placeholder="WordPress with WooCommerce"
                        dense
                        required
                        :menu-props="{
                          offsetY: true,
                        }"
                      >
                        <template v-slot:no-data>
                          <loader />
                        </template>
                      </v-select>
                    </div>
                  </v-tab-item> -->

                  <!-- CLEAN INSTALLATION FORM -->

                  <v-tab-item>
                    <v-row no-gutters>
                      <v-col cols="12" class="py-0 d-flex flex-column">
                        <span class="error--text" v-if="wordpressNoVersions"
                          >No Versions Available!</span
                        >
                        <v-select
                          v-else
                          v-model="newInstanceForm.version"
                          :items="wordpressVersions"
                          outlined
                          dense
                          bottom
                          required
                          :menu-props="{
                            offsetY: true,
                          }"
                        >
                          <template v-slot:no-data>
                            <loader />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <v-row>
                      <v-col cols="12">
                        <span class="error--text" v-if="noInstances"> </span>
                        <v-select
                          v-else
                          v-model="newInstanceForm.clone_instance_id"
                          item-value="id"
                          item-text="name"
                          :items="instances"
                          outlined
                          bottom
                          :menu-props="{
                            offsetY: true,
                          }"
                          dense
                          required
                        >
                          <template v-slot:no-data>
                            <loader />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </v-form>
          </v-tab-item>
          <v-tab-item v-show="hasAdvancedFields">
            <v-form class="form-box" ref="form">
              <v-row no-gutters>
                <v-col cols="12" class="pb-0 d-flex flex-column">
                  <template v-if="selectedService.standard_fields && selectedService.standard_fields.language">
                    <label class="base--text p-3 mb-2"> Language </label>
                    <v-select
                      offset-y
                      v-model="newInstanceForm.language"
                      :items="languages"
                      item-text="label"
                      item-value="value"
                      :menu-props="{
                        offsetY: true,
                      }"
                      dense
                      outlined
                    >
                      <template v-slot:no-data>
                        <loader />
                      </template>
                    </v-select>
                  </template>

                  <template
                    v-if="selectedService.standard_fields && selectedService.standard_fields.database_name"
                  >
                    <label class="base--text p-3 mb-2"> Database Name </label>
                    <v-text-field
                      v-model="newInstanceForm.db_name"
                      outlined
                      dense
                      hide-details="auto"
                      validate-on-blur
                      class="v-input--md"
                    ></v-text-field>
                  </template>

                  <template
                    v-if="selectedService.standard_fields && selectedService.standard_fields.database_prefix"
                  >
                    <label class="base--text p-3 mb-2"> Database Prefix </label>
                    <v-text-field
                      v-model="newInstanceForm.db_prefix"
                      outlined
                      dense
                      hide-details="auto"
                      validate-on-blur
                      class="v-input--md"
                    ></v-text-field>
                  </template>
                </v-col>

                <v-col
                  cols="6"
                  class="py-0 pr-2 d-flex flex-column"
                  v-if="selectedService.standard_fields && selectedService.standard_fields.admin_username"
                >
                  <label class="base--text p-3 mb-2"
                    >Administrator Username
                  </label>
                  <v-text-field
                    v-model="newInstanceForm.admin_username"
                    outlined
                    :rules="nameRules"
                    placeholder="admin"
                    dense
                    required
                    hide-details="auto"
                    validate-on-blur
                    class="v-input--md"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="6"
                  class="py-0 pl-2 d-flex flex-column"
                  v-if="selectedService.standard_fields && selectedService.standard_fields.admin_email"
                >
                  <label class="base--text p-3 mb-2"
                    >Administrator Email
                  </label>
                  <v-text-field
                    v-model="newInstanceForm.admin_email"
                    outlined
                    :rules="emailRules"
                    placeholder="test@nicedomain.com"
                    dense
                    hide-details="auto"
                    validate-on-blur
                    required
                    type="email"
                    class="v-input--md"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="py-0 d-flex flex-column"
                  v-if="selectedService.standard_fields && selectedService.standard_fields.admin_password"
                >
                  <label class="base--text p-3 mb-2">
                    Administrator Password
                  </label>
                  <div class="d-flex input-group">
                    <v-text-field
                      v-model="newInstanceForm.admin_password"
                      outlined
                      dense
                      hide-details="auto"
                      validate-on-blur
                      :rules="adminPasswordRules"
                      required
                      class="v-input--md"
                      :type="showPassword ? 'text' : 'password'"
                      :autofocus="false"
                      autocomplete="new-password"
                    ></v-text-field>
                    <v-btn
                      icon
                      tile
                      outlined
                      large
                      color="gray"
                      class="mr-4 ml-4"
                      @click="showPassword = !showPassword"
                    >
                      <v-icon color="black">{{
                        showPassword ? "$eye" : "$eyeclosed"
                      }}</v-icon>
                    </v-btn>
                    <v-btn
                      @click.prevent="
                        newInstanceForm.admin_password = generatePassword()
                      "
                      large
                      outlined
                      color="gray"
                    >
                      <span class="black--text">Generate Password</span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions
        class="px-8 py-8 d-flex align-center justify-space-between"
      >
        <div>
          <step-indicator :maxSteps="3" :currentStep="1"></step-indicator>
        </div>

        <div class="d-flex">
          <v-btn
            color="gray"
            large
            elevation="0"
            text
            @click="$emit('removeModal')"
            class="mr-3 ml-auto"
          >
            <span class="gray--text text--darken-1"> Back </span>
          </v-btn>
          <v-btn
            v-if="retryInstall"
            color="primary"
            large
            elevation="0"
            @click="submitRetryInstall()"
            :loading="submitting"
          >
            Retry Install
            <template v-slot:loading>
              <loader />
            </template>
          </v-btn>
          <v-btn
            v-else
            color="primary"
            large
            elevation="0"
            @click="submitForm()"
            :loading="submitting"
          >
            Continue

            <v-icon color="white" size="20" class="ml-2 mr-0">
              $arrowright
            </v-icon>
            <template v-slot:loading>
              <loader />
            </template>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormRulesMixin from "../../mixins/FormRulesMixin";
import Loader from "@/components/Loader.vue";
import Api from "@/apis/Api";
import StepIndicator from "../StepIndicator.vue";

export default {
  mixins: [FormRulesMixin],
  components: {
    Loader,
    StepIndicator,
  },
  data: function () {
    return {
      tabs: 0,
      advancedView: false,
      serverAccounts: [],
      wordpressVersions: [],
      wordpressNoVersions: false,
      wordpressTemplates: [],
      wordpressNoTemplates: false,
      instances: [],
      noInstances: false,
      availableDomainProtocols: ["https://", "http://"],
      subdirectoryInputOpen: false,
      newInstanceForm: {
        install_method: "standard",
        theme: this.selectedTheme.id || null,
        install_type: "clean",
        server_account_id: null,
        name: "",
        domainProtocol: "https://",
        domain: "",
        language: "en",
        admin_username: "admin",
        admin_password: "",
        admin_email: "",
        dir: "",
        version: "",
        protocol: "https://",
        db_name: "",
        db_prefix: "",
        template: "",
        clone_instance_id: "",
      },
      languages: [
        { value: "en", label: "English" },
        { value: "ar", label: "Arabic" },
        { value: "sq", label: "Albanian" },
        { value: "bg_BG", label: "Bulgarian" },
        { value: "ca", label: "Catalan" },
        { value: "zh_CN", label: "Chinese(Simplified)" },
        { value: "zh_TW", label: "Chinese(Traditional)" },
        { value: "hr", label: "Croatian" },
        { value: "cs_CZ", label: "Czech" },
        { value: "da_DK", label: "Danish" },
        { value: "nl_NL", label: "Dutch" },
        { value: "fi", label: "Finnish" },
        { value: "fr_FR", label: "French" },
        { value: "de_DE", label: "German" },
        { value: "el", label: "Greek" },
        { value: "he_IL", label: "Hebrew" },
        { value: "hu_HU", label: "Hungarian" },
        { value: "id_ID", label: "Indonesian" },
        { value: "it_IT", label: "Italian" },
        { value: "ja", label: "Japanese" },
        { value: "ko_KR", label: "Korean" },
        { value: "nb_NO", label: "Norwegian" },
        { value: "fa_IR", label: "Persian" },
        { value: "pl_PL", label: "Polish" },
        { value: "pt_PT", label: "Portuguese" },
        { value: "pt_BR", label: "Portuguese-BR" },
        { value: "ro_RO", label: "Romanian" },
        { value: "ru_RU", label: "Russian" },
        { value: "sl_SI", label: "Slovenian" },
        { value: "sk_SK", label: "Slovak" },
        { value: "es_ES", label: "Spanish" },
        { value: "sv_SE", label: "Swedish" },
        { value: "th", label: "Thai" },
        { value: "tr_TR", label: "Turkish" },
        { value: "uk", label: "Ukrainian" },
        { value: "vi", label: "Vietnamese" },
        { value: "zh_HK", label: "Chinese (Hong Kong)" },
      ],
      showPassword: false,
      activeTab: 0,
      submitting: false,
      submittingError: "",
      submittingSuccess: "",
      retryInstall: false,
      retryInstanceId: 0,
    };
  },
  mounted() {
    this.$root.$on("instance-retry-install-with-form", (instance) => {
      this.retryInstall = true;
      this.retryInstanceId = instance.id;
      let params = instance.install_details;
      for (const [param, value] of Object.entries(params)) {
        if (typeof this.newInstanceForm[param] !== "undefined") {
          this.newInstanceForm[param] = value;
        }
      }
      switch (params.install_type) {
        // case "from_template":
        //   this.activeTab = 0;
        //   break;
        case "clean":
          this.activeTab = 0;
          break;
        case "clone":
          this.activeTab = 1;
          break;
      }
      if (params.dir) {
        this.subdirectoryInputOpen = true;
      }
      this.open = true;
    });

    // get available versions
    Api.get("/instances/wordpress-versions").then((response) => {
      if (!response.data.data.length) {
        this.wordpressNoVersions = true;
      } else {
        let data = [];
        for (const ver of response.data.data) {
          data.push({
            text: ver,
            value: ver,
          });
        }
        data[0].text += ` - latest`;
        this.wordpressVersions = data;
        this.newInstanceForm.version = data[0].value;
      }
    });

    // get available templates
    Api.get("/instances/wordpress-templates").then((response) => {
      if (!response.data.data.length) {
        this.wordpressNoTemplates = true;
        // this.activeTab = 1;
      } else {
        this.wordpressTemplates = response.data.data;
        if (this.wordpressTemplates.length) {
          this.newInstanceForm.template = this.wordpressTemplates[0].id;
        }
      }
    });

    // get instances for cloning
    let instances = [];

    Api.get("/instances?page=1&per_page=100&sort_by=name&sort_dir=asc").then(
      (response) => {
        if (!response.data.data.length) {
          this.noInstances = true;
        } else {
          for (let instance of response.data.data) {
            instances.push({
              id: instance.id,
              name: instance.name,
            });
          }
          this.instances = instances;
          this.newInstanceForm.clone_instance_id = this.instances[0].id;
          this.newInstanceForm.instance = this.instances[0];
        }
      }
    );

    // random default db name
    // this.newInstanceForm.db_name = this.generateDbName();

    // default admin email from current user
    this.newInstanceForm.admin_email = this.userEmail;

    // random admin password
    this.newInstanceForm.admin_password = this.generatePassword();
  },
  props: {
    persistent: {
      type: Boolean,
      default: false,
    },
    isOpen: Boolean,
    selectedTheme: Object,
    selectedService: Object,
  },
  computed: {
    isDemo() {
      return this.$store.state.home.user.email == "demo@panelalpha.com";
    },
    userEmail: function () {
      if (this.$store.state.home.user) {
        return this.$store.state.home.user.email;
      } else {
        return "";
      }
    },
    dialogOpen: {
      get: function () {
        return this.open;
      },
      set: function () {
        this.$emit("closeModal");
      },
    },
    isPersistent: {
      get: function () {
        return this.persistent;
      },
      set: function (value) {
        this.$emit("isPersistent", value);
      },
    },
    hasAdvancedFields() {
      for (const field in this.selectedService.standard_fields) {
        if (this.selectedService.standard_fields[field] === true) {
          return true;
        }
      }
      return false;
    },
    selectedServiceId() {
      return this.selectedService.id || 0;
    },
  },
  methods: {
    closeSubdirectoryInput: function () {
      this.subdirectoryInputOpen = false;
      this.newInstanceForm.dir = "";
    },
    openAdvancedView: function () {
      this.advancedView = true;
    },
    closeAdvancedView: function () {
      this.advancedView = false;
    },
    submitForm() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.isDemo) {
        this.submitting = true;
        this.isPersistent = true;
        setTimeout(() => {
          this.submitting = false;
          this.isPersistent = false;
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: "Instance Created.",
          });
          this.$emit("removeModal");
        }, 1000);
        return;
      }

      this.submitting = true;
      this.submittingError = "";
      this.submittingSuccess = "";
      this.isPersistent = true;

      let installParams = this.newInstanceForm;
      installParams.service_id = this.selectedServiceId;

      this.newInstanceForm.theme = this.selectedTheme.slug;

      Api.post("/instances/install", this.newInstanceForm)
        .then((response) => {
          this.submitting = false;
          this.isPersistent = false;
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: "Instance Created. Installation In Progress.",
          });
          this.$root.$emit("instance-created", response.data.data);
          this.$emit("removeModal");
        })
        .catch((error) => {
          this.submitting = false;

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
          this.isPersistent = false;
        });
    },
    submitRetryInstall() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.submitting = true;
      this.submittingError = "";
      this.submittingSuccess = "";
      this.isPersistent = true;

      Api.put(
        `/instances/${this.retryInstanceId}/retry-install`,
        this.newInstanceForm
      )
        .then((response) => {
          this.submitting = false;
          this.isPersistent = false;
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: "Installation In Progress.",
          });
          this.$root.$emit("instance-item-changed", response.data.data);

          this.$emit("removeModal");
        })
        .catch((error) => {
          this.submitting = false;
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
          this.isPersistent = false;
        });
    },
    generateRandomString(chars, length) {
      let random = "";
      for (let i = 0; i < length; i++) {
        let randomNumber = Math.floor(Math.random() * chars.length);
        random += chars.substring(randomNumber, randomNumber + 1);
      }
      return random;
    },
    generatePassword() {
      return this.generateRandomString(
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
        10
      );
    },
    generateDbName() {
      return this.generateRandomString(
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
        7
      );
    },
  },
  watch: {
    // wordpressNoTemplates: function (value) {
    //   if (value) {
    //     this.activeTab = 1;
    //   }
    // },
    isOpen: function (newState) {
      if (newState) {
        this.advancedView = false;
        this.$nextTick(() => {
          if(this.$refs.dialog) {
              this.$refs.dialog.$el.addEventListener(
              "mouseup",
              this.handleClickOutside
            );

            this.$store.dispatch("lockBodyScroll");

            this.$refs.dialog.$el.scrollTop = 0;
          }
        });
      } else {
        this.retryInstall = false;
        this.$nextTick(() => {
          // this.$refs.dialog.$el.removeEventListener(
          //   "mouseup",
          //   this.handleClickOutside
          // );

          this.$store.dispatch("unlockBodyScroll");
        });
      }

      this.$nextTick(() => {
        document.activeElement.blur();
      });
    },
    "newInstanceForm.install_type": function (value) {
      if (value === "clone" && this.newInstanceForm.clone_instance_id === "") {
        this.newInstanceForm.clone_instance_id = this.instances[0].id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content::v-deep {
  .v-dialog {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.v-card::v-deep {
  .v-window {
    overflow: visible;
  }
  .v-tabs-items {
    background-color: transparent;
  }
  .v-tabs > .v-tabs-bar .v-tab {
    font-size: $font-size-base;
    line-height: $line-height-base;
  }
  .v-card__text {
    -webkit-line-clamp: unset !important;
  }

  .v-text-field--outlined {
    &.v-input.v-text-field {
      margin-bottom: 16px;
    }
    > .v-input__control > .v-input__slot {
      background-color: #fff;
    }
  }
  .form-box {
    background-color: #fcfcff;
    border: 1px solid var(--v-gray-lighten4);
    border-radius: 8px;
    padding: 16px 24px;
    grid-gap: 16px 12px;
    .full {
      grid-column: 1 / 3;
    }
  }
  .error-box {
    background-color: var(--v-error-lighten5);
    border-radius: 8px;
    padding: 16px;
    display: flex;
  }

  @media (max-width: 480px) {
    .v-card__text,
    .v-card__actions {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
  }
}

.v-tabs::v-deep {
  .v-tab.v-tab--disabled {
    pointer-events: all;
    * {
      pointer-events: all;
    }
    &::before {
      pointer-events: none;
    }
  }
}
</style>
